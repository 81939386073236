<template>
  <div class="visit-temporary-new">
    <!-- 搜索框-->
    <van-row style="background-color: white">
      <van-search v-model="value" placeholder="请输入临时拜访名称"/>
    </van-row>
    <van-row>
      <van-col span="11" :style="{fontSize:'15px'}">当前查询数据条数：3条</van-col>
    </van-row>
    <!-- 列表-->
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div ref="fresh" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
        <div v-if="list.length<=0">
          <van-empty image="error" description="没有数据"/>
        </div>
        <div v-else>
          <van-row>
            <van-list>
              <visit-temporary-cell v-for="item in list" v-bind:key="item.id" :data="item"/>
            </van-list>
          </van-row>
        </div>
      </div>
    </van-pull-refresh>
    <!--    <port-info-select-->
    <!--        title="多条件选择"-->
    <!--        message="多条件选择"-->
    <!--        :showDialog="isShowDialog"-->
    <!--        @sendmsg='getMsg'-->
    <!--        @closeDialog="isShowDialog=false, isAll= !isAll"></port-info-select>-->
    <!-- 添加按钮-->
    <Add to="/BulkCrmsVisitTemporaryNewAdd/" :param=Math.random().toString(36).slice(2)></Add>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="2"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import VisitTemporaryCell from '@/components/VisitTemporaryCell/index'
import Add from '@/components/Add';
export default {
  name: "BulkCrmsVisitTemporaryNewList",
  components: {VisitTemporaryCell, CrmTabBar, Add},
  data() {
    return {
      isAll: false,
      isShowDialog: false,
      colors: '',
      value: '',
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      //上拉标识
      loading: false,
      //上拉加载完成
      finished: false,
      list: [],
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - this.$refs.fresh.getBoundingClientRect().top - 50
    this.onRefresh();
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.list = []
        for (let i = 0; i < 3; i++) {
          let data = {
            title: '山东华海鲁运',
            date: '2022-10-26',
            fzr: '王帅',
            cusType: '船方',
            to: 'BulkCrmsVisitTemporaryNewEdit',
            detail: '查看详情'
          }
          this.list.push(data);
        }
        // 刷新状态结束
        this.isLoading = false;
        this.finished = false
      }, 1000);
    },
    reverseAll() {
      this.isAll = !this.isAll
      this.isShowDialog = true;
    },
    getMsg(data) {
      this.isAll = !this.isAll
      this.isShowDialog = false;
      console.log(data)
    },
  }
}
</script>

<style scoped>

</style>